import React from 'react'

export const LogoSvg = () => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        height="48"
        width="48"
        style={{marginRight: "10px"}}
    >
        <path d="M446.499 276.099C414.401 237.4 365.5 198.1 308.5 165.099c-17.1-9.899-34.799-18.9-52.5-26.999-94.2-43.2-192.001-59.401-220.8-9.6-15.3 26.7-4.501 64.799 30.3 107.399 32.098 38.701 80.999 78.001 137.999 111 17.1 9.901 34.501 18.9 52.5 27.001 53.401 24.6 107.999 40.199 150.601 40.199 32.399 0 57.9-9 70.199-30.599 15.301-26.7 4.501-64.801-30.3-107.401zM451 368.5c-15.601 26.999-98.699 19.499-195-27.601-12.299-5.999-24.901-12.599-37.5-19.799-54-31.2-100.201-68.4-129.6-104.4-25.8-31.201-36.301-58.5-27.9-73.2 6-10.201 21.899-15.601 44.099-15.601 36.601 0 91.201 14.101 150.901 43.2 12.299 6 24.901 12.599 37.5 19.799C347.5 222.1 393.701 259.3 423.1 295.3c25.8 31.199 36.301 58.5 27.9 73.2z" fill="#17ace8"/>
        <path d="M476.8 383.5c-12.299 21.599-37.8 30.599-70.199 30.599-42.601 0-97.2-15.599-150.601-40.199v-33.001c96.301 47.1 179.399 54.6 195 27.601 8.401-14.7-2.1-42.001-27.9-73.2-29.399-36-75.601-73.2-129.6-104.401-12.599-7.2-25.201-13.799-37.5-19.799v-33c17.701 8.099 35.4 17.1 52.5 26.999 57.001 33.001 105.901 72.301 137.999 111C481.3 318.699 492.1 356.8 476.8 383.5z" fill="#1689fc"/>
        <path d="M476.8 128.5c-28.5-49.801-126.599-33.6-220.8 9.6-17.701 8.099-35.4 17.1-52.5 26.999-57.001 33.001-105.901 72.301-137.999 111C30.7 318.699 19.9 356.8 35.2 383.5c12.299 21.599 37.8 30.599 70.199 30.599 42.601 0 97.2-15.599 150.601-40.199 17.999-8.101 35.4-17.1 52.5-27.001 57.001-32.999 105.901-72.299 137.999-111C481.3 193.299 492.1 155.2 476.8 128.5zm-53.7 88.2c-29.399 36-75.601 73.2-129.6 104.399-12.599 7.2-25.201 13.801-37.5 19.799-96.301 47.1-179.399 54.6-195 27.601-8.401-14.7 2.1-42.001 27.9-73.2 29.399-36 75.601-73.2 129.6-104.401 12.599-7.2 25.201-13.799 37.5-19.799 60-29.099 114.3-43.2 150.901-43.2 22.2 0 38.099 5.4 44.099 15.601 8.401 14.7-2.1 41.999-27.9 73.2z" fill="#1de8f1"/>
        <path d="M446.499 235.899C414.401 274.6 365.5 313.9 308.5 346.899c-17.1 9.901-34.501 18.9-52.5 27.001v-33.001c12.299-5.999 24.901-12.599 37.5-19.799 54-31.2 100.201-68.4 129.6-104.4 25.8-31.201 36.301-58.5 27.9-73.2-6-10.201-21.899-15.601-44.099-15.601C370.3 127.899 316 142 256 171.099V138.1c94.2-43.2 192.299-59.401 220.8-9.6 15.3 26.7 4.5 64.799-30.301 107.399z" fill="#1bd9de"/>
        <path d="M256 0c-68.101 0-105 132.4-105 256s36.899 256 105 256 105-132.4 105-256S324.101 0 256 0zm0 482c-35.4 0-75-97.301-75-226 0-128.701 39.6-226 75-226s75 97.299 75 226c0 128.699-39.6 226-75 226z" fill="#32d736"/>
        <path d="M361 256c0 123.6-36.899 256-105 256v-30c35.4 0 75-97.301 75-226 0-128.701-39.6-226-75-226V0c68.101 0 105 132.4 105 256z" fill="#10bb67"/>
        <path d="M256 211c-24.901 0-45 20.099-45 45 0 24.899 20.099 45 45 45s45-20.101 45-45c0-24.901-20.099-45-45-45z" fill="#fd3c65"/>
        <path d="M256 301v-90c24.901 0 45 20.099 45 45 0 24.899-20.099 45-45 45z" fill="#fd003a"/>
    </svg>
);
